import TonButton from "../../components/TonButton/TonButton";
import { TopControl } from "../../components/TopControl";
import { BigEggSvg } from "./BigEggSvg";

export const Airdrop = () => {
  return (
    <>
      <TopControl name="airdrop" />
      <div className="px-2">
        <div className="max-w-[660px] mx-auto flex flex-col items-center justify-center">
          <h1 className="text-white text-xs font-normal">AirDrop Tasks</h1>
          <p className="text-center text-xs font-normal text-[#677496] mt-3">
            Partnerships are on their way. Tasks will appear below. Complete
            them to participate in the Airdrop.
          </p>
          <p className="text-center text-xs font-bold text-[#677496] mt-3">
            The more BILLIES you collect, the bigger the airdrop value will be!
          </p>
        </div>

        <div className="max-w-[750px] mx-auto ">
          <h1 className="text-center text-white text-xs font-bold mt-5">
            Tasks
          </h1>

          <div className="mt-3 space-y-3">
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2">
              <img
                src="/img/airdrop/img-1.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  LIVECARDS DISCOUNT DROPS
                </h2>
                <div className="flex gap-1 mt-2">
                  <img
                    src="/img/airdrop/lock-icon.svg"
                    className="size-3"
                    alt="Icon"
                  />
                  <p className="text-[#677496] font-bold text-xs">
                    Click to Unlock
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2">
              <img
                src="/img/airdrop/img-2.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">
                  In-Game Credit Drops
                </h2>
                <div className="flex gap-1 mt-2">
                  <img
                    src="/img/airdrop/lock-icon.svg"
                    className="size-3"
                    alt="Icon"
                  />
                  <p className="text-[#677496] font-bold text-xs">
                    Click to Unlock
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-[#333A48B2] w-full flex items-center gap-5 rounded-[40px] px-5 py-2 opacity-50">
              <img
                src="/img/airdrop/img-3.png"
                alt="Image 1"
                className="w-[30px]"
              />
              <div>
                <h2 className="text-white text-xs font-normal">Token Drop</h2>
                <div className="flex gap-1 mt-2">
                  <p className="text-[#677496] font-bold text-xs">
                    COMMING SOON
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
