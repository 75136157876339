import React, { useState, useEffect, useContext } from "react";
import { useWebSocket } from "../../hooks/useWebsocket";
import { getTelegramUser } from "../../services/telegramService";
import { useUser } from "../../hooks/useUser";
import { TaskSkeleton } from "./TaskSkeleton";
import { TaskModal } from "./TaskModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EggSvg } from "../Layout/EggSvg";

import { EggNimbus } from "../../components/EggNimbus";
import { TaskList } from "./TaskList";
import { NotifyContext, NotifyMessage } from "../../contexts/NotifyContext";
import { DataContext } from "../../contexts/DataContext";
import { Task } from "../../models";
import { TopControl } from "../../components/TopControl";
import { DailyTask } from "../../components/DailyTask";

const News = [
  {
    rewardAmount: "100,000",
    avatarUrl: "./img/youtube.png",
    name: "YOUTUBE TITLE",
    description: "None",
    active: true,
    type: "link",
    activateUrl:
      "https://stackoverflow.com/questions/9768444/possible-eventemitter-memory-leak-detected",
    completed: false,
  },
  {
    rewardAmount: "100,000",
    avatarUrl: "./img/youtube.png",
    name: "YOUTUBE TITLE",
    description: "None",
    active: true,
    type: "link",
    activateUrl:
      "https://stackoverflow.com/questions/9768444/possible-eventemitter-memory-leak-detected",
    completed: false,
  },
];
const dailyRewards = [
  {
    rewardAmount: "6,649,000",
    avatarUrl: "./img/calendar.png",
    name: "DAILY REWARDS",
    description: "None",
    active: true,
    type: "link",
    activateUrl:
      "https://stackoverflow.com/questions/9768444/possible-eventemitter-memory-leak-detected",
    completed: false,
  },
];

export const Tasks = () => {
  const { webSocket } = useWebSocket();
  const { user } = useUser();
  const [isDataLoading, setDataLoading] = useState(false);
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const notifyContext = useContext(NotifyContext);
  const dataContext = useContext(DataContext);

  useEffect(() => {
    if (webSocket) {
      webSocket.emit("getTasks");

      webSocket.on("taskStatus", (data) => {
        const { id, finished } = data;

        setTimeout(() => {
          dataContext?.setTasks((prevTasks: any) => {
            return prevTasks.map((task: any) =>
              task._id == id ? { ...task, completed: finished } : task
            );
          });

          let notify: NotifyMessage;
          if (finished) {
            notify = {
              message: "Task completed!",
              status: "task",
              className: "h-96",
            };
          } else {
            notify = {
              message: "Task not completed yet.",
              status: "error",
              className: "h-96",
            };
          }
          setSelectedTask(null);
          notifyContext?.setNotify(notify);
        }, 3000);
      });
    }
    return () => {
      webSocket?.off("taskStatus");
    };
  }, [webSocket]);

  const handleTaskClick = (task: any) => {
    setSelectedTask(task);
  };

  const handleModalClose = () => {
    setSelectedTask(null);
  };

  const handleOpenLink = () => {
    if (selectedTask && selectedTask._id) {
      const task = dataContext?.tasks.find(
        (task: any) => task._id === selectedTask._id
      );
      const isTaskCompleted = task?.completed;

      if (selectedTask.type !== "telegram" && !isTaskCompleted) {
        const tgUserId = getTelegramUser().id;
        webSocket?.emit(
          "checkTaskStatus",
          JSON.stringify([tgUserId, selectedTask._id])
        );
      }

      window.open(selectedTask.activateUrl, "_blank");
    }
  };

  const handleCheckStatus = () => {
    const tgUserId = getTelegramUser().id;
    webSocket?.emit(
      "checkTaskStatus",
      JSON.stringify([tgUserId, selectedTask._id])
    );
  };
  return (
    <>
      <TopControl name="earn" />
      <div className="font-sans p-5 rounded-lg max-w-md mx-auto">
        <div className="w-full flex justify-center items-center mb-3">
          <img src="./img/coins.png" className="w-[50px] h-[50px]" />
        </div>
        <div className="text-center text-xs mb-3 font-bold">
          Earn more coins
        </div>
        {isDataLoading ? (
          <TaskSkeleton />
        ) : (
          <ul
            className="list-none p-0 no-scrollbar"
            style={{ maxHeight: window.innerHeight - 367, overflowY: "scroll" }}
          >
            <div className="text-xs text-center text-white font-normal">
              Game Goat News by Billy
            </div>
            <div className="mt-3">
              {/* <DailyTask /> */}
              <ul className="space-y-2">
                {News.map((task: any, index) => (
                  <li
                    key={index}
                    className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm"
                    onClick={() => handleTaskClick(task)}
                  >
                    <div className="w-full flex flex-row justify-left items-center">
                      <img
                        src={task.avatarUrl}
                        className="mr-1 rounded-full w-[55px] h-[55px] p-3 object-scale-down"
                      />
                      <div>
                        <span
                          className={
                            task.completed
                              ? "text-xs font-light	text-gray-500 line-through leading-none"
                              : "text-xs font-light text-white leading-none"
                          }
                        >
                          {task.name}
                        </span>
                        <span className="flex flex-row justify-left ml-2 items-center">
                          <div className="flex gap-2 items-center">
                            <div className="size-2 bg-[#E0AE10] rounded-full"></div>
                            <img src="./img/coin.png" className="w-5 h-5" />
                          </div>
                          <div className="ml-1 text-xs text-[#E0AE10] font-bold">
                            +{task.rewardAmount}
                          </div>
                          <div className="text-xs text-[#677496] font-bold ml-1">
                            Billies
                          </div>
                        </span>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
            <div className="pt-2">
              <h1 className="text-center text-xs font-normal pb-5">
                Daily Tasks
              </h1>
              {dailyRewards.map((task: any, index) => (
                <li
                  key={index}
                  className="p-1 my-2 bg-[#333A48B2] rounded-2xl flex justify-between items-center shadow-sm"
                  onClick={() => handleTaskClick(task)}
                >
                  <div className="w-full flex flex-row justify-left items-center">
                    <img
                      src={task.avatarUrl}
                      className="mr-2 rounded-full w-[55px] h-[55px] p-3 object-scale-down"
                    />
                    <div>
                      <span
                        className={
                          task.completed
                            ? "text-xs font-light	text-gray-500 line-through leading-none"
                            : "text-xs font-light text-white leading-none"
                        }
                      >
                        {task.name}
                      </span>
                      <span className="flex flex-row justify-left ml-2 items-center">
                        <div className="flex gap-2 items-center">
                          <div className="size-2 bg-[#E0AE10] rounded-full"></div>
                          <img src="./img/coin.png" className="w-5 h-5" />
                        </div>
                        <div className="ml-1 text-xs text-[#E0AE10] font-bold">
                          +{task.rewardAmount}
                        </div>
                        <div className="text-xs text-[#677496] font-bold ml-1">
                          Billies
                        </div>
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </div>
            <div className="text-center text-xs font-normal py-3">
              Tasks List
            </div>
            <TaskList
              tasks={dataContext?.tasks}
              handleTaskClick={handleTaskClick}
              filter="link"
            />
          </ul>
        )}
      </div>
      {selectedTask && (
        <TaskModal
          task={selectedTask}
          onClose={handleModalClose}
          onOpenLink={handleOpenLink}
          onCheckStatus={handleCheckStatus}
        />
      )}
    </>
  );
};
